import React from 'react';
import './App.css';
import Menu from './components/menu/Menu';
import Gradient from './components/gradient/Gradient';
//import Animation from './components/animation/animation';

function App() {
  return (
    <>
    <Menu />
    <Gradient />
    </>
  );
}

export default App;
