export const dataprojects = [
  {
    id: 1,
    name: 'MMMatch',
    category: 'Web App de phrase ❤️ / ',
    link: 'https://mmmatch.me',
    content: "Création du logo ainsi que de l'identité visuelle. Tech: Javascript / React JS.",
    img: '/img/mmmatch/mmmatch.png',
    img2: '/img/mmmatch/screenOne.png',
    img3: '/img/mmmatch/screenTwo.png',
    img4: '/img/mmmatch/screenThree.png',
  },
  {
    id: 2,
    name: 'Pavane',
    category: 'eShop de créateur',
    content: "Création du logo & mise en place du eshop.",
    img: '/img/pavane/pavanastudio.png',
    img2: '/img/pavane/sh1.png',
    img3: '/img/pavane/sh2.png',
    img4: '/img/pavane/sh3.png',

  },
  {
    id: 3,
    name: 'Glow Math',
    category: 'Web App Mathématique / ',
    link: "https://glow-math.com",
    content: "work in progress / Création Ui UX / Tech : React JS, CSS, HTML",
    img: '/img/glowmath/GLOWMATH.png',
  },
  {
    id: 4,
    name: 'b10c03sha',
    category: 'Artiste NFT / ',
    link: 'https://b10c03sha.space',
    content: "Création Ui UX & coding Javascript. Développement de NFT.",
    img: '/img/b10c03sha/december_2018.jpg',
    img2: '/img/b10c03sha/sc2.png',
    img3: '/img/b10c03sha/sc1.png',
  },
  {
    id: 5,
    name: 'Ni Queue ni Tête',
    category: "Web App d'expression Francaises",
    content: "Création d'une web app en Javascript, framework React JS & conception de l'identité visuelle.",
    img: '/img/niqueuenitete/niqueuenitetete.png',
    img2: '/img/niqueuenitete/sc1.png',
    img3: '/img/niqueuenitete/sc2.png',
    img4: '/img/niqueuenitete/sc3.png',
  },
  {
    id:6,
    name: 'UX UI Design',
    category: 'Créations web & app',
    content: "Créations UX & UI de differents projets web & app, l'accent est mis sur l'expérience utilisateur et la convivialité, ils sont conçus pour être intuitifs et faciles à utiliser, avec des navigation claires et des boutons de call-to-action bien positionnés. des animations, des effets de transition et des images de fond en mouvement pour donner vie à leurs designs. De plus, avec l'essor des appareils mobiles, les designs web doivent être optimisés pour une utilisation sur différentes tailles d'écran. avec l'avènement de la technologie et l'augmentation de la capacité de stockage, les designs web sont devenus plus complexes et plus visuels. pour ajouter de la profondeur et de la créativité aux sites web. le développement du design web continue d'être influencé par les tendances technologiques et les attentes des utilisateurs. Ceci consiste à être constamment à la recherche de nouvelles façons de rendre les sites web plus attractifs, interactifs et engageants pour les utilisateurs.",
    img: '/img/uiux/latin-psd.png',
    img2: '/img/uiux/store-fashion-website.png',
    img3: '/img/uiux/creative-store-website.png',
    img4: '/img/uiux/Homepage.png',
    img5: '/img/uiux/layer-font-vintage.jpg',
  },
];
