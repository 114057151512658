import React from 'react';
import './knowledge.css';
import { useEffect, useState } from 'react';
import Main from './components/Main';

const Knowledge = () => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState()


    useEffect(() => {
        fetch("https://ipapi.co/json/")
            .then(res => res.json())
            .then(
                (result) => {
                    fetch("https://api.openweathermap.org/data/2.5/weather?lat=" + result.latitude + "&lon=" + result.longitude + "&appid=e86f416c73fcf7da8fc6c93aa286dfd1&units=metric")
                        .then(res => res.json())
                        .then(
                            (result) => {
                                setIsLoaded(true);
                                setItems(result);
                            },
                            (error) => {
                                setIsLoaded(true);
                                setError(error);
                            })
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])


    if (error) {
        return (
            <>



                <div>Error: {error.message}</div>
                <Main></Main>
            </>
        )
    } else if (!isLoaded) {
        return (
            <>

                <div>Loading...</div>
                <Main></Main>

            </>
        )
    } else {
        return (

            <>
                <div className='box-stalker slide-top'>
                <p>longitude: {items.coord.lon}</p>
                    <p>latitude: {items.coord.lat}</p>
                    <p>city : {items.name}</p>
                    <p>weather: {items.weather[0].main}</p>
                    <p>pressure: {items.main.pressure} hPa</p>
                    <p>degree: {items.main.temp}</p>
                    <p>humidity: {items.main.humidity} %</p>
                </div>

                <Main></Main>


            </>
        );
    }
};

export default Knowledge;

/*

https://api.openweathermap.org/data/2.5/weather?lat=47.21640226167966&lon=-1.5508726488398776&appid=e86f416c73fcf7da8fc6c93aa286dfd1&units=metric

47.21640226167966, -1.5508726488398776

 */
