import React from 'react';
import './projects.css';
import { Link } from 'react-router-dom';
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

import projetwebdesigncreative from '../../assets/img/cover/latin-psd.png';
import pavaneshop from '../../assets/img/cover/pavanastudio.png';
import logoMMMatchp from '../../assets/img/cover/mmmatch.png';
import b10c03sha from '../../assets/img/cover/screen-b10c03sha.png';
import glowmath from '../../assets/img/cover/GLOWMATH.png';
import niqueuenitetete from '../../assets/img/cover/niqueuenitetete.png';


const Projects = () => {

    return (
        <div className='container-project'>

            <MouseParallaxContainer globalFactorX={0.5} globalFactorY={0.5}>

                <MouseParallaxChild factorX={0.7} factorY={0.7}>
                    <Link to="/project/1"><img className='p-one scale-in-center image'src={logoMMMatchp}  alt="MMMatch" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.7} factorY={0.4}>
                    <Link to="/project/2"><img className='giftwo scale-in-center image' src={pavaneshop} alt="pavane" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.8} factorY={0.2}>
                    <Link to="/project/3"><img className='gifthree scale-in-center image' src={glowmath} alt="Glow Math" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.5} factorY={0.6}>
                    <Link to="/project/4"><img className='giffour scale-in-center image' src={b10c03sha} alt="b10c03sha" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.7} factorY={0.8}>
                    <Link to="/project/5"><img className='giffive scale-in-center image' src={niqueuenitetete} alt="Ni Queue ni Tête" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.3} factorY={0.9}>
                    <Link to="/project/6"><img className='gifsix scale-in-center image' src={projetwebdesigncreative} alt="UX UI Design" /></Link>
                </MouseParallaxChild>

            </MouseParallaxContainer>
        </div>
    );
};

export default Projects;

/**
 
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

            <MouseParallaxContainer globalFactorX={0.5} globalFactorY={0.5}>

                <MouseParallaxChild factorX={0.7} factorY={0.7}>
                    <Link to="/project/1"><img className='p-one' src={projetwebdesigncreative} alt="gifone" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.7} factorY={0.4}>
                    <Link to="/project/2"><img className='giftwo' src={logoMMMatchp} alt="giftwo" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.8} factorY={0.2}>
                    <Link to="/project/3"><img className='gifthree' src={pavaneshop} alt="gifthree" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.5} factorY={0.6}>
                    <Link to="/project/4"><img className='giffour' src={logotype} alt="giffour" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.7} factorY={0.8}>
                    <Link to="/project/5"><img className='giffive' src={logotype} alt="giffive" /></Link>
                </MouseParallaxChild>

                <MouseParallaxChild factorX={0.3} factorY={0.9}>
                    <Link to="/project/6"><img className='gifsix' src={logotype} alt="gifsix" /></Link>
                </MouseParallaxChild>

            </MouseParallaxContainer>













 */