import React from 'react';
import './follow.css';

const Follow = () => {
    return (
        <>
            <div className='follow-container'>
            <h3><a href="https://t.me/enztx">✹ telegram ✹</a></h3>
            <h3><a href="https://github.com/enztexier">✹ github ✹</a></h3>
            <h3><a href="https://www.are.na/enzo-texier">✹ are.na ✹</a></h3>
            <h3><a href="https://www.instagram.com/moncy.studio/">✹ instagram ✹</a></h3>
            <h3>✹ enzotexier.studio@gmail.com ✹</h3>
            </div>
        </>
    );
};

export default Follow;