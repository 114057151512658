import React from 'react';
import './main.css';

const Main = () => {
    return (
        <div>
            <div className='container-knowledge'>
                <h1 className='slide-top'>DU CODE RIEN QUE DU CODE</h1>
                <p className='text-focus-in' >
                    <br></br>
                    La force du javascript, permet de d'aboutir à toutes les créations.
                </p>
            </div>
            <div className='coding slide-in-bottom'>
                <p>
                    JAVASCRIPT
                </p>
                <p>
                    REACT JS
                </p>
                <p>
                    NODE JS
                </p>
                <p>
                    MONGO DB
                </p>
                <p>
                    Typescript
                </p>
                <p>
                    CSS
                </p>
                <p>
                    Angular JS
                </p>
                <p>
                    Vue JS
                </p>
                <p>
                    Polymer
                </p>
                <p>
                    Bootstraps
                </p>
                <p>
                    Redux
                </p>
                <p>
                    GIT
                </p>
                <p>
                    NPM
                </p>
                <h3>
                    CLOSE
                </h3>

            </div>
        </div>
    );
};

export default Main;