import React from 'react';

const Lost = () => {
    return (
        <div>
            <h1>YOU LOST</h1>

        </div>
    );
};

export default Lost;