import React from 'react';
import "./home.css";
import logo from '../../assets/img/logo-moncy.png';

const Home = () => {
    return (
      <>
      <div className='center'>
        <img className='logo' src={logo} alt="logo" />
      </div>
      </>
    );
};

export default Home;
