import React from 'react';
import './believe.css';

const Believe = () => {
    return (
        <>
        <div className='container-believe'>
            <h1 className='slide-top'>SIMPLE COMME BONJOUR</h1>

        <div className='box-step'>
            <h4>COMPRENDRE</h4><h4>REFLECHIR</h4><h4>CREER</h4>
        </div>
        <div className='baseline'>
        <h3>Des expériences digitales créatives en harmonie avec la technologie</h3>
        </div>
        </div>
        </>
    );
};

export default Believe;


/*****
 * 
 * 
 * 





         <h1 className='slide-top'>CREATIONS ARTISTIQUE <br></br> EN HARMONIE AVEC LA TECHNOLOGIE</h1>
            <p className='text-focus-in'>Des expériences digitales créatives et innovantes, basées sur le design & l'expérience utilisateur.</p>
 */