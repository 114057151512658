import React from 'react';
import { useParams } from 'react-router-dom';
import './detail.css';

const Detail = ({ props }) => {

    const { id } = useParams()
    let nbr = Number(id)
    const detail = props.filter((project) => project.id === nbr)
    let data = detail[0]

    return (
        <>
            <div className='detail'>
                <h1 className='tracking-in-expand'>{data.name}</h1>
                <h2 className='slide-top'>{data.category}<a href={data.link}>{data.link}</a></h2>
                <img className='logo-detail slide-in-left' src={`${data.img}`} alt="logo" />
                <p className='text-focus-in'>{data.content}</p>
                <div className='container-detail'>
                {data.img2 && <img className='img slide-in-bottom' src={`${data.img2}`} onError={(event) => event.target.style.display = 'none'}/>}
                {data.img3 && <img className='img' src={`${data.img3}`} onError={(event) => event.target.style.display = 'none'}/>}
                {data.img4 && <img className='img' src={`${data.img4}`} onError={(event) => event.target.style.display = 'none'}/>}
                {data.img5 && <img className='img' src={`${data.img5}`} onError={(event) => event.target.style.display = 'none'}/>}
                </div>
            </div>
        </>
    );
};

export default Detail;