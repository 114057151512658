import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import './menu.css';
import { Routes, Route } from 'react-router-dom';
import Home from '../../pages/home/Home';
import Knowledge from '../../pages/knowledge/Knowledge';
import Projects from '../../pages/projects/Projects';
import Believe from '../../pages/believe/Believe';
import Follow from '../../pages/follow/Follow';
import Lost from '../../pages/lost/Lost';
import Detail from '../../pages/detail/Detail';
import squareone from '../../assets/img/square-one.png';
import squaretwo from '../../assets/img/square-two.png';
import squarethree from '../../assets/img/square-three.png';
import squarefour from '../../assets/img/square-four.png';
import { dataprojects } from '../../assets/data/dataprojects.js';

const Menu = () => {

  const [menumove, setMenu] = useState(false);

  const menuClass = () => {
    setMenu(!menumove)
  }
  return (
    <>
      <div className="container">
        <div className="one">
          <Link to="/"><img className='squareimg' src={squareone} alt="squareone" /></Link>
        </div>
        <div className="two">
          <p><Link to="/believe">PROCESSUS</Link></p>
        </div>
        <div className="three">
          <img className='squareimg' src={squaretwo} alt="squaretwo" />
        </div>
        <div className="four">
          <p><Link to="/knowledge">TECHNIQUES</Link></p>
        </div>
        <div className="five">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/knowledge" element={<Knowledge />} />
            <Route path="/believe" element={<Believe />} />
            <Route path="/follow" element={<Follow />} />
            <Route path="/project/:id" element={<Detail props={dataprojects} />} />
            <Route path="*" element={<Lost />} />
          </Routes>
        </div>
        <div className="six">
          <p><Link to="/follow">CONTACT</Link></p>
        </div>
        <div className="seven">
          <img className='squareimg' src={squarethree} alt="squarethree" />
        </div>
        <div className="eight"><Link to="/projects">PROJETS</Link></div>
        <div className="nine">
          <img className='squareimg' src={squarefour} alt="squarefour" />
        </div>
      </div>


      <div className="container-r">

        <div className="five">

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/knowledge" element={<Knowledge />} />
            <Route path="/believe" element={<Believe />} />
            <Route path="/follow" element={<Follow />} />
            <Route path="/project/:id" element={<Detail props={dataprojects} />} />
            <Route path="*" element={<Lost />} />
          </Routes>

        </div>


        <div className={`menu-btn ${menumove ? "menuopen" : ""}`} onClick={menuClass}>

          {
            menumove
              ?

              <div className="menu-r">
                <p><Link to="/">HOME</Link></p>
                <p><Link to="/believe">PROCESSUS</Link></p>
                <p><Link to="/knowledge">TECHNIQUES</Link></p>
                <p><Link to="/projects">PROJETS</Link></p>
                <p><Link to="/follow">CONTACT</Link></p>
              </div>

              :
              <>
                <img className='squareimg' src={squarefour} alt="squarefour" />
                <img className='squareimg' src={squaretwo} alt="squaretwo" />
                <img className='squareimg' src={squarethree} alt="squarethree" />
              </>
          }




        </div>

      </div>



    </>



  );
};

export default Menu;